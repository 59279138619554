import { render, staticRenderFns } from "./Tagline.vue?vue&type=template&id=492836f5&"
import script from "./Tagline.vue?vue&type=script&lang=js&"
export * from "./Tagline.vue?vue&type=script&lang=js&"
import style0 from "./Tagline.vue?vue&type=style&index=0&module=true&lang=css&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VRow})
